// @flow strict
import { useStaticQuery, graphql } from 'gatsby';

/**
 * The following social icons were removed from site
 * siteMetadata {
 *  author {
 *    contacts {
 *      facebook
 *      telegram
 *      instagram
 *      rss
 *      vkontakte
 *      line
 *      gitlab
 *      weibo
 *      codepen
 *      youtube
 *      soundcloud
 *      medium
 *    }
 *  }
 * }
 */

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            author {
              name
              bio
              photo
              contacts {
                linkedin
                github
                twitter
                email
              }
            }
            menu {
              label
              path
            }
            url
            title
            subtitle
            copyright
            disqusShortname
          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
